import { checkPermission, getAllWeekDays, getFirstThreeLetter, getStaffId, toPascalCase } from '../../helpers/utils'
import { AVAILABILITY, AVAILABILITY_HELP, DELETE_WARNING, SET_TEMP_AVAILABILITY, MY_AVAILABILITY } from '../../constants/strings'
import withSidebar from '../../hoc/withSidebar'
import WarningModal from '../../components/warning_modal/WarningModal'
import ToastAlert from '../../components/alert/ToastAlert'
import { AlertVariant, PageMode, PermissionKeys, toastMessageInitialData } from '../../constants/constants'
import { useContext, useEffect, useState } from 'react'
import {
	AddAvailabilityResponse,
	AvailabilityData,
	AvailabilityFormData,
	AvailabilityWeekFormData,
	SingleAvailabilityFormData,
	ToastMessageProps,
} from '../../constants/staticTypes'
import { CommonCtx } from '../../context/CommonCtxProvider'
import AvailabilityDataGrid from '../../components/availability/AvailabilityDataGrid'
import AvailabilitySideBar from '../../components/availability/AvailabilitySideBar'
import { Badge } from 'primereact/badge'
import { FaCaretRight, FaEdit } from 'react-icons/fa'
import { useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { currentWeekDaysString, deleteStaffAvailability, getAvailabilityOfUsers } from '../../helpers/availabilityHelper'
import useQueryHook from '../../hooks/useQueryHook'
import Loader from '../../components/loader/Loader'
import { AVAILABILITY_LIST } from '../../constants/queryKeys'
import useMutationHook from '../../hooks/useMutationHook'
import dayjs from 'dayjs'
import { ScheduleManagementCtx } from '../../context/ScheduleManagementCtxProvider'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import MlxTooltip from '../../components/common/MlxTooltip'
import MlxPopover from '../../components/common/MlxPopover'
import { useWindowSize } from 'react-use'

function Availability() {
	const queryClient = useQueryClient()
	const availabilityMutation = useMutationHook(queryClient, true)
	const staffId = getStaffId()
	const currentWeek = currentWeekDaysString()
	const {width: screenWidth} = useWindowSize()

	// STATE VARIABLE
	const [mode, setMode] = useState<PageMode>(PageMode.ADD)
	const [availabilityType, setAvailabilityType] = useState<'REGULAR' | 'TEMPORARY'>('REGULAR')
	const [availabilityList, setAvailabilityList] = useState<AvailabilityData[]>([])
	const [temporaryAvailabilityList, setTemporaryAvailabilityList] = useState<AvailabilityData[]>([])
	const [regularAvailability, setRegularAvailability] = useState<AvailabilityData | null>(null)
	const [deleteAvailability, setDeleteAvailability] = useState<SingleAvailabilityFormData>({}) // delete availability
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
	const [showWarning, setWarning] = useState(false) // Warning popup
	const [warningMessage, setWarningMessage] = useState('') // Warning message

	// CONTEXT VARIABLE
	const { showSideBar, setShowSideBar } = useContext(CommonCtx)
	const { setAvailabilityData } = useContext(ScheduleManagementCtx)
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext

	const [translationText, setTranslatedText] = useState<{
		editAvailability: string,
	}>({ 
		editAvailability: "Edit Regular Availability",
	});

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: "Edit Regular Availability", typeCase: 'pascal', translationContext }),
				])
				
				setTranslatedText({
					editAvailability: translations[0] ?? "Edit Regular Availability",
				})
			} catch {
				setTranslatedText({
					editAvailability: "Edit Regular Availability",
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	const currentWeekDays = getAllWeekDays(dayjs())

	useEffect(() => {
		const regularAvailabilityData = availabilityList.find((availability) => availability.isRecurring)
		if (regularAvailabilityData) {
			setRegularAvailability(regularAvailabilityData)
		} else {
			setRegularAvailability(null)
		}

		const availabilityListByTemporary = availabilityList.filter(
			(availability) => availability.isRecurring === false
		)
		if (availabilityListByTemporary) {
			setTemporaryAvailabilityList(availabilityListByTemporary)
		}
	}, [availabilityList])

	// availability list fetch success
	const onAvailabilityListFetchSuccess = (res: AxiosResponse<any, any>) => {
		const availabilityListData = (res.data.data.lists as AvailabilityData[]) || ([] as AvailabilityData[])
		setAvailabilityList(availabilityListData)
	}

	// fetch availability list
	const availabilityListData = useQueryHook(
		AVAILABILITY_LIST,
		() => {
			return getAvailabilityOfUsers({ staffId, startDate: currentWeek[0] })
		},
		onAvailabilityListFetchSuccess
	)

	if (availabilityListData.isLoading) {
		return <Loader />
	}

	const onAvailabilityDeleteClick = (availability: AvailabilityData) => {
		setDeleteAvailability({ id: availability.id })
		setWarningMessage(DELETE_WARNING)
		setWarning(true)
	}

	const deleteAvailabilityApiCall = () => {
		deleteStaffAvailability(availabilityMutation, deleteAvailability, onSuccess, onError)
	}

	const onSuccess = (res: AddAvailabilityResponse) => {
		queryClient.refetchQueries([AVAILABILITY_LIST])
		setShowSideBar(false)
		setToastMessage({ message: res.message, show: true, variant: AlertVariant.SUCCESS })
	}

	const onError = (message: string) => {
		setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
	}

	type BadgeSeverityType = 'success' | 'info' | 'warning' | null | undefined
	const getBadgeStatus = (status: string): BadgeSeverityType => {
		switch (status) {
			case 'PENDING':
				return 'warning'
			case 'APPROVED':
				return 'success'
			default:
				return 'info'
		}
	}

	return (
		<>
			<WarningModal
				show={showWarning}
				title={warningMessage}
				onHide={() => {
					setWarning(false)
					setDeleteAvailability({})
				}}
				callback={() => {
					setWarning(false)
					deleteAvailabilityApiCall()
				}}
			/>

			<ToastAlert
				data-testid="toast"
				message={toastMessage.message}
				onClose={() => setToastMessage(toastMessageInitialData)}
				show={toastMessage.show}
				variant={toastMessage.variant}
			/>

			{/* availability sidebar */}
			<AvailabilitySideBar
				availabilityType={availabilityType}
				pageMode={mode}
				setMode={setMode}
				setShowSideBar={setShowSideBar}
				setToastMessage={setToastMessage}
			/>

			<div className="col-md-12 col-lg-12 mb-3 h-100 ">
				<div className="page-title">
					<h5 className="d-flex">
						<JTranslation typeCase="pascal" text={AVAILABILITY} />
						<MlxPopover data={{title:AVAILABILITY, body:AVAILABILITY_HELP}}/>
					</h5>
				</div>

				<div className="card h-100 overflow-auto">
					<div className="card-header flex-grow-0">
						<div className="d-flex align-items-center">
							<div className="flex-grow-1 py-2">
								<h4 className="mb-0 title"><JTranslation typeCase="pascal" text={MY_AVAILABILITY} /></h4>
							</div>
							<div className="flex-grow-0 me-2 ms-1">
							</div>
						</div>
					</div>
					<div className="card-body flex-grow-1 overflow-auto">
						<div className="h-100 d-flex flex-column p-2">
							<div className="mt-2 d-flex flex-row justify-content-between availability-border">
								<div className="d-flex align-items-center">
									<strong><JTranslation typeCase="pascal" text={"Regular Availability"} /></strong>
									{regularAvailability && (
										<Badge
											className="ms-3 custom-badge"
											severity={getBadgeStatus(regularAvailability.actionStatus)}
											value={<JTranslation typeCase="pascal" text={toPascalCase(regularAvailability.actionStatus)} />}
										></Badge>
									)}
								</div>
								{checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY) && (
									<MlxTooltip target='.edit-availability'>
										<FaEdit
											data-test-id={'regular-availability-edit-icon'}
											size={22}
											className='edit edit-availability'
											data-pr-tooltip={translationText.editAvailability}
											data-pr-position="left"
											onClick={(e) => {
												e.preventDefault()

												if (
													regularAvailability &&
													checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY)
												) {
													const weekDays = getAllWeekDays(dayjs(regularAvailability.startDate))
													const editableData: AvailabilityFormData = {
														id: regularAvailability.id,
														isRecurring: 'recurring',
														startDate: currentWeekDays[0],
														endDate: currentWeekDays[6] ?? null,
														staffId: regularAvailability.staffId,
														weekdays: regularAvailability.weekdays
															? regularAvailability.weekdays.map((day, index) => {
																	const weekDay: AvailabilityWeekFormData = {
																		isAllDay: day.isAllDay,
																		isAvailable: day.isAvailable,
																		weekday: weekDays[index],
																		notes: day.notes ?? '',
																		availableTimes: day.availableTimes
																			? day.availableTimes.map((time) => ({
																					from: dayjs(
																						`${weekDays[index].format(
																							'MM/DD/YYYY'
																						)} ${time.from}`
																					),
																					to: dayjs(
																						`${weekDays[index].format(
																							'MM/DD/YYYY'
																						)} ${time.to}`
																					),
																			}))
																			: [],
																	}
																	return weekDay
															})
															: [],
													}
													setAvailabilityData(editableData)
													setMode(PageMode.EDIT)
													setShowSideBar(true)
												}

												setAvailabilityType('REGULAR')
												setShowSideBar(true)
											}}
										/>
									</MlxTooltip>
								)}
							</div>
							<div className="regular-availability-row">
								{regularAvailability?.weekdays
									? regularAvailability.weekdays.map((day, index) => (
											<div
												key={index + 1}
												className={`p-2 d-flex flex-column justify-content-center align-items-center ${
													index + 1 <
													(regularAvailability?.weekdays
														? regularAvailability?.weekdays?.length
														: 7)
														? 'border-right'
														: ''
												}`}
											>
												{screenWidth > 800 ? (
													<>
														<p>{getFirstThreeLetter(day.weekday)}</p>
														<p className={day.isAvailable ? 'success' : 'danger'}>
															{day.isAvailable 
																? <JTranslation typeCase="pascal" text={'Available'} /> 
																: <JTranslation typeCase="pascal" text={'Not Available'} />
															}
														</p>
														<p>
															{day.isAllDay && day.isAvailable
																? <JTranslation typeCase="pascal" text={'All Day'} />
																: day?.availableTimes?.map((times, index) => (
																		<p key={index} className="text-warning">
																			{times.from} - {times.to}
																		</p>
																))}
															{!day.isAllDay && day.notes && (
																<p className="text-warning">{day.notes}</p>
															)}
														</p>
													</>
												) : (
													<>
														<span>
															<p>{getFirstThreeLetter(day.weekday)}</p>
															<p>
																{day.isAllDay && day.isAvailable
																	? <JTranslation typeCase="pascal" text={'All Day'} />
																	: day?.availableTimes?.map((times, index) => (
																			<p key={index} className="text-warning">
																				{times.from} - {times.to}
																			</p>
																	))}
																{!day.isAllDay && day.notes && (
																	<p className="text-warning">{day.notes}</p>
																)}
															</p>
														</span>
														<p className={day.isAvailable ? 'success' : 'danger'}>
															{day.isAvailable 
																? <JTranslation typeCase="pascal" text={'Available'} /> 
																: <JTranslation typeCase="pascal" text={'Not Available'} />
															}
														</p>
													</>
												)}												
											</div>
									  ))
									: currentWeekDays?.map((day, index) => (
											<div
												key={index + 1}
												className={`p-2 d-flex flex-column justify-content-center align-items-center ${
													index + 1 < currentWeekDays?.length ? 'border-right' : ''
												}`}
											>
												<p>{day.format('dddd')}</p>
												<p className="success"><JTranslation typeCase="pascal" text={"Available"} /></p>
												<p><JTranslation typeCase="pascal" text={"All Day"} /></p>
											</div>
									  ))}
							</div>

							<div className="mt-5 d-flex flex-row justify-content-between">
								<div className="d-flex align-items-center">
									<b><JTranslation typeCase="pascal" text={"Temporary Availability"} /></b>
								</div>
								{checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY) && (
									<button
										data-test-id={'temporary-availability-add-btn'}
										className="btn btn-custom-primary d-flex  icon-only-btn"
										type="button"
										onClick={() => {
											if (checkPermission(queryClient, PermissionKeys.MANAGE_AVAILABILITY)) {
												setMode(PageMode.ADD)
												setAvailabilityType('TEMPORARY')
												setShowSideBar(true)
											}
										}}
									>
										<i className="ri-add-circle-line me-1"></i>
										<span className="btn-text"><JTranslation typeCase="pascal" text={SET_TEMP_AVAILABILITY} /></span>
									</button>
								)}
							</div>

							{temporaryAvailabilityList.length ? (
								<AvailabilityDataGrid
									queryClient={queryClient}
									tableData={temporaryAvailabilityList}
									setMode={setMode}
									setToastMessage={setToastMessage}
									setShowSideBar={setShowSideBar}
									showSideBar={showSideBar}
									onAvailabilityDeleteClick={onAvailabilityDeleteClick}
									setAvailabilityType={setAvailabilityType}
								/>
							) : (
								<div className="mt-1 d-flex flex-column justify-content-center">
									<div className="p-4 d-flex align-items-center mt-2 add-on-group-box">
										<div className="empty-addon-group">
											<FaCaretRight size={18} className="noAddon-icon" />
											<span className="mx-1"><JTranslation typeCase="pascal" text={"No Temporary Availability"} /></span>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default withSidebar(Availability)
