import { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { DATE_FORMAT, MOBILE_VIEW_BREAKPOINT, toastMessageInitialData } from '../../constants/constants'
import { DatePicker } from 'antd'
import { getStaffId } from '../../helpers/utils'
import { JTranslation } from '../../helpers/jTranslate'
import { PublishedSchedule, fetchUserPublishedListForWeek, getWeekRange } from '../../helpers/workTimeScheduleHelper'
import { ToastMessageProps } from '../../constants/staticTypes'
import { USER_PUBLISHED_LIST_FOR_WEEK } from '../../constants/queryKeys'
import { useWindowSize } from 'react-use'
import { WORK_TIME_SCHEDULE } from '../../constants/strings'
import dayjs from 'dayjs'
import StaffSchedule from '../../components/staff_schedule/StaffSchedule'
import StaffScheduleMobileView from '../../components/staff_schedule/StaffScheduleMobileView'
import ToastAlert from '../../components/alert/ToastAlert'
import useQueryHook from '../../hooks/useQueryHook'
import withSidebar from '../../hoc/withSidebar'

const StaffScheduleView = () => {
    const { width } = useWindowSize()
    const breakPoint = MOBILE_VIEW_BREAKPOINT
    const staffId = getStaffId()
    const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs>(dayjs())
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [scheduleData, setScheduleData] = useState<PublishedSchedule[]>([]);
    const dateRange = getWeekRange(selectedWeek); // Calculate the date range for the selected week

    const onSuccess = (res: AxiosResponse<any, any>) => {
        const publishedSchedule: PublishedSchedule[] = res.data.data
        setScheduleData(publishedSchedule)
    }

    // fetch shift list (api call)
    const scheduleQuery = useQueryHook(USER_PUBLISHED_LIST_FOR_WEEK, () => fetchUserPublishedListForWeek({ tenantStaffId: staffId, dateRange: dateRange, separateBySchedule: true }), onSuccess)

    // refetch the published schedule api once the selected date has changed 
    useEffect(() => {
        scheduleQuery.refetch()
    }, [selectedWeek]) // eslint-disable-line

    return (
        <>
            <div className="page-title d-flex">
                <h5 className="d-flex">
                    <JTranslation typeCase="pascal" text={WORK_TIME_SCHEDULE} />
                </h5>
                {/* TOAST MESSAGE COMPONENT */}
                <ToastAlert
                    show={toastMessage.show}
                    onClose={() => setToastMessage(toastMessageInitialData)}
                    message={toastMessage.message}
                    variant={toastMessage.variant}
                />
            </div>
            <div className="col-md-12 col-lg-12 mb-3 h-100 ">
                <div className="card h-100 ">
                    <div className="card-header flex-grow-0">
                        <div className="d-flex  align-items-center">
                            <div className="flex-grow-1"></div>
                            <div className="flex-grow-0 me-2 ms-1">
                                <span>
                                    <DatePicker
                                        onChange={(date, dateString) => {
                                            setSelectedWeek(dayjs(date))
                                        }}
                                        picker="week"
                                        value={selectedWeek}
                                        format={(value) =>
                                            `${dayjs(value).startOf('week').format(DATE_FORMAT)} - ${dayjs(value)
                                                .endOf('week')
                                                .format(DATE_FORMAT)}`
                                        }
                                        allowClear={false}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body flex-grow-1 overflow-auto">
                        <div className="survey-section h-100">
                            {/* switch web and mobile components */}
                            {width >= breakPoint
                                ? <StaffSchedule selectedWeek={selectedWeek} scheduleData={scheduleData} isFetching={scheduleQuery.isFetching} />
                                : <StaffScheduleMobileView selectedWeek={selectedWeek} scheduleData={scheduleData} isFetching={scheduleQuery.isFetching} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withSidebar(StaffScheduleView)
